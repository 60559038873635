import React, { Component } from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import history from './history';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const LoginForm = ({loginSubmit}) => {
  let email;
  let password;

  return (
    <div className="App">
      <header className="header">
        <h3 className="title-header">Accès Eps</h3>
      </header>

      <div className="col-md-12 vertical-center">
        <div className="form-group col-md-4">
          <h5 className="title">connexion</h5>
          <input type="text" className="form-control" placeholder="Email" ref={loginValue => {
            email = loginValue;
          }} />
          <input type="password" className="form-control" placeholder="Password" ref={passwordValue => {
            password = passwordValue;
          }} />
          <button 
            className="btn btn-info form-control"
            onClick={(e) => {
              e.preventDefault();
              loginSubmit(email.value,password.value);
              email.value = '';
              password.value = '';
            }}>Valider</button>
        </div>
      </div>
    </div>
  );
};

class LoginClass extends Component {
  loginSubmit(email,password) {
    const formData = new FormData();

    formData.append('email', email)
    formData.append('password', password)

    axios.post('/api/login', formData)
    .then((res) => {
      if(res.data.data[0] !== undefined && res.data.data[0].email)
      {
	console.log(res.data)
        if(res.data.session.authenticated === true)
        {
          cookies.set('authenticated', 'true', { path: '/', maxAge: '3600' });
          cookies.set('statut', res.data.data[0].statut, { maxAge: '3600' });
          history.push('/dashboard');
          history.go('/dashboard');
          this.setState({messageStatut: 'Success'});
        }
        else
        {
          this.setState({messageStatut: 'Doesn\'t exist'});
        }
      }
      else
      {
        this.setState({messageStatut: 'Doesn\'t exist'});
      }
    });
  }

  render() {
    return (
      <LoginForm loginSubmit={this.loginSubmit.bind(this)}/>
    );
  }
}

export default LoginClass;
