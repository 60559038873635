import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

import LoginClass from "./App";
import DashboardClass from "./Dashboard"
import TestClass from "./App.test";

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const fakeAuth = {
  isAuthenticated: cookies.get('authenticated')
  ,rules: cookies.get('rules')
  ,filter: cookies.get('filter')
}

const PrivateRoute = () => (
  <Route  exact path="/dashboard" render={(props) => (
    fakeAuth.isAuthenticated === 'true'
      ? <DashboardClass location={props.location} />
      : <Redirect to='/' />
  )} />
)

const PrivateRoute2 = () => (
  <Route  exact path="/public" render={(props) => (
    fakeAuth.isAuthenticated === 'true'
      ? <TestClass location={props.location} />
      : <Redirect to='/' />
  )} />
)

const PrivateRoute3 = () => (
  <Route  exact path="/image" render={(props) => (
    fakeAuth.isAuthenticated === 'true'
      ? <TestClass location={props.location} />
      : <Redirect to='/' />
  )} />
)

class Main extends Component {
  render() {
    return (
      <Router>
          <div className="groupContainer">
            <div className="content">
              <Route exact path="/" component={LoginClass}/>
              <PrivateRoute />
              <PrivateRoute2 />
              <PrivateRoute3 />
            </div>
          </div>
      </Router>
    );
  }
}

export default Main;