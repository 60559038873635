import React, { Component } from 'react';
import Image from 'react-bootstrap/Image'
import axios from 'axios';

const ListFiles = ({allFiles,loadNewFile,fullRoute,previousFile,onChangeHandler,onChangeHandler2,onClickHandler,onClickHandler2,deleteImage,deletePath}) => {
  let listButton = []
  let listImage = []
  let ButtonPrevious = []
  let ButtonUpload = []
  let ButtonUpload2 = []
  const regex = /([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif|svg|mp4))/i
  const regexBis = /([a-z\-_0-9\/\:\.]*\.(pdf))/i
  const regexBis2 = /([a-z\-_0-9\/\:\.]*\.(psd))/i
  const regex2 = /([a-z\-_0-9\/\:\.]*\.(ico|html|json|ttf|txt|pdf|psd))/i

  Array.from(allFiles).forEach((value) => {
  	if(value[0] !== '.') {
  	  	if(regex.test(value)) {
  	  		var route = "/content-application" + fullRoute + "/" + value
          var path = fullRoute + "/" + value

  	  		listImage.push(
              <div style={{ 'width':'min-content','display':'inline-table', 'position': 'relative', 'marginTop': '20px' }}>
                <button className="btnDeleteImg" style={{ 'zIndex': 100000 }} onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ' + value + ' ?')===true){deleteImage(path)}}}>X</button>
  	  		      <a key={value} style={{'cursor':'pointer', 'zIndex': 10}} onClick={(e) => {e.stopPropagation(); window.open(route)}}>
                  <img src={route} style={{'width': '120px','height': '120px','marginLeft': '20px', 'marginBottom': '20px'}} />
  	  		      </a>
              </div>
          )
  	  	}
  	}
  });

  Array.from(allFiles).forEach((value) => {
    if(value[0] !== '.') {
        if(regexBis.test(value)) {
          var route = "/content-application" + fullRoute  + "/" + value
          var path = fullRoute + "/" + value

          listImage.push(
              <div style={{ 'width':'min-content','display':'inline-table', 'position': 'relative', 'marginTop': '20px' }}>
                <button className="btnDeleteImg" style={{ 'zIndex': 100000 }} onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ' + value + ' ?')===true){deleteImage(path)}}}>X</button>
                <a key={value} style={{'cursor':'pointer', 'zIndex': 10}} onClick={(e) => {e.stopPropagation(); window.open(route)}}>
                  <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg" style={{'width': '120px','height': '120px','marginLeft': '20px', 'marginBottom': 20}} />
                  <p style={{ 'marginBottom': '20px', 'marginTop': -20 }}>{value}</p>
                </a>
              </div>
          )
        }
    }
  });

  Array.from(allFiles).forEach((value) => {
    if(value[0] !== '.') {
        if(regexBis2.test(value)) {
          var route = "/content-application" + fullRoute  + "/" + value
          var path = fullRoute + "/" + value

          listImage.push(
              <div style={{ 'width':'min-content','display':'inline-table', 'position': 'relative', 'marginTop': '20px' }}>
                <button className="btnDeleteImg" style={{ 'zIndex': 100000 }} onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ' + value + ' ?')===true){deleteImage(path)}}}>X</button>
                <a key={value} style={{'cursor':'pointer', 'zIndex': 10}} onClick={(e) => {e.stopPropagation(); window.open(route)}}>
                  <img src="/psd-ico.png" style={{'width': '120px','height': '120px','marginLeft': '20px', 'marginBottom': 20}} />
                  <p style={{ 'marginBottom': '20px', 'marginTop': -20, 'marginLeft': '20px', 'textAlign': 'center' }}>{value}</p>
                </a>
              </div>
          )
        }
    }
  });

  Array.from(allFiles).forEach((value) => {
  	if(value[0] !== '.') {
  	  	if(!regex.test(value)) {
  	  		if(!regex2.test(value)) {
  	  			listButton.push(<li key={value} style={{'cursor':'pointer', 'fontSize': '23px'}} onClick={(e) => {loadNewFile(value)}}>{value}<button className="btnDeleteImg2" style={{ 'zIndex': 100000 }} onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ' + value + ' ?')===true){deletePath(fullRoute + "/" + value)}}}>X</button></li>)
  	  		}
  	  	}
  	}
  });

  if(fullRoute !== '') {
  	ButtonPrevious.push(
  		<div key="previous">
	    	<button onClick={(e) => {previousFile()}}>Précédent</button>
	    </div>
	  )
  }

  ButtonUpload.push(
    <div style={{ 'width':300, 'marginBottom': 50 }}>
      <input type="file" name="file" accept="video/mp4,video/x-m4v,video/*,image/*,.pdf" multiple onChange={(e) => {onChangeHandler(e.target.files)}} />
      <button type="button" class="btn btn-success btn-block" onClick={(e) => {e.preventDefault(); onClickHandler()}}>Upload</button> 
    </div>
  )

  ButtonUpload2.push(
    <div style={{ 'width':300, 'marginBottom': 50 }}>
      <input type="text" name="text" placeholder="Nouveau dossier" onChange={(e) => {onChangeHandler2(e.target.value)}} />
      <button type="button" class="btn btn-success btn-block" onClick={(e) => {e.preventDefault(); onClickHandler2()}}>Create</button> 
    </div>
  )

  return (
  	<div>
  		{listImage}
	    <ul style={{'marginTop':'20px', 'width': 'fit-content'}}>
	      {listButton}
	    </ul>
      {ButtonUpload}
      {ButtonUpload2}
	   	{ButtonPrevious}
	</div>
  )
}

class TestClass extends Component {
  constructor(props) {
  	super(props);
  	this.state = {
  		allFiles: [],
  		fullRoute: '',
  		arrayRoute: [],
      selectedFile: [],
      loaded: 0
  	}
  }

  loadCycle() {
  	const formData = new FormData()

  	formData.append('route', '')

  	var arrayRoute = this.state.arrayRoute
  	arrayRoute.push('')

    axios.post('/api/getFiles', formData) 
    .then((res) => {
      if(res.data) {
        this.setState({
          allFiles: res.data,
          arrayRoute: arrayRoute
        })
      }
    });
  }

   loadCycle2(route) {
    const formData = new FormData()

    formData.append('route', route)

    axios.post('/api/getFiles', formData) 
    .then((res) => {
      if(res.data) {
        this.setState({
          allFiles: res.data
        })
      }
    });
  }

  loadNewFile(value) {
  	var route = this.state.fullRoute

  	route += "/" + value

  	const formData = new FormData()

    formData.append('route', route)

    var arrayRoute = this.state.arrayRoute
    arrayRoute.push(value)

    axios.post('/api/getFiles', formData) 
    .then((res) => {
      if(res.data) {
        this.setState({
          allFiles: res.data,
          fullRoute: route,
          arrayRoute: arrayRoute
      	})
      }
    });
  }

  previousFile() {
  	var route = this.state.arrayRoute
  	var path = ''

  	route.splice(-1,1)

  	if(route.length > 1) {
	  	route.forEach(value => {
	  		if(value !== '') {
	  			path += '/' + value
	  		}
	  	})
	  }
  	
    const formData = new FormData()

    formData.append('route', path)

  	axios.post('/api/getFiles', formData) 
    .then((res) => {
      if(res.data) {
        this.setState({
          allFiles: res.data,
          fullRoute: path,
          arrayRoute: route
      	})
      }
    });
  }

  onChangeHandler(files) {
    console.log(files)
    this.setState({
      selectedFile: files,
      loaded: 0,
    })
  }

  onChangeHandler2(path) {
    this.setState({
      newFolder: path
    })
  }

  onClickHandler() {
    const data = new FormData()
     for (let i = 0; i < this.state.selectedFile.length; i++) {
      data.append(`${i}`, this.state.selectedFile[i])
    }

    data.append('path', this.state.fullRoute)

    axios.post("/api/uploadFile", data, {
      headers: {
        'Content-Type': '/json'
      }
    })
    .then((res) => { 
      this.loadCycle2(this.state.fullRoute);
    })
  }

  onClickHandler2() {
     const data = new FormData()
     data.append('path', this.state.fullRoute)
     data.append('newFolder', this.state.newFolder)

     axios.post("/api/createFolder", data)
     .then((res) => { 
       this.loadCycle2(this.state.fullRoute);
     })
  }

  deleteImage(path) {
    const data = new FormData()
    data.append('path', path)
    
    axios.post("/api/deleteFile", data)
    .then((res) => { 
      this.loadCycle2(this.state.fullRoute);
    })
  }

  deletePath(path) {
    const data = new FormData()
    data.append('path', path)
    
    axios.post("/api/deletePath", data)
    .then((res) => { 
      this.loadCycle2(this.state.fullRoute);
    })
  }

  componentDidMount() {
    this.loadCycle();
  }

  render() {
    return (
    	<div>
        <ListFiles allFiles={this.state.allFiles} loadNewFile={this.loadNewFile.bind(this)} fullRoute={this.state.fullRoute} previousFile={this.previousFile.bind(this)} onChangeHandler={this.onChangeHandler.bind(this)} onChangeHandler2={this.onChangeHandler2.bind(this)} onClickHandler={this.onClickHandler.bind(this)} onClickHandler2={this.onClickHandler2.bind(this)} deleteImage={this.deleteImage.bind(this)} deletePath={this.deletePath.bind(this)} />
      </div>
    );
  }
}

export default TestClass;