import React, { Component, useMemo, useRef, useState, useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import Fade from 'react-reveal/Fade';
import Cookies from 'universal-cookie';
import history from './history';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactHtmlParser from 'react-html-parser';
import { Editor } from '@tinymce/tinymce-react';

const cookies = new Cookies();

const Deconnexion = ({deconnexion}) => {
  return (
    <div className="headerRight">
      <button className="btnDeco" onClick={(e) => {deconnexion()}}>Déconnexion</button>
    </div>
  )
}

const Dashboard = ({showModal,boolShow,categorieSelected}) => {
  let visible = categorieSelected > 0 ? true : false

  var statut = cookies.get("statut")
  
  if(statut !== 'correcteur') {
    return (
      <div className={categorieSelected !== 0 ? "" : "hide"}>
        <Fade left when={visible}>
          <div className="col-md-12">
            <div className="col-md-4 ">
              <button className="btn btn-success create" onClick={(e) => {window.scroll(0,0); boolShow(showModal)}}><i className="fas fa-plus faicon"></i>nouvelle activité</button>
            </div>
          </div>
        </Fade>
      </div>
    );
  } else {
    return (
      <div></div>
    )
  }
};

const Dashboard2 = ({showModalEchauffement,boolShowEchauffement,echauffementSelected}) => {
  let visible = echauffementSelected > 0 ? true : false

  var statut = cookies.get("statut")
  
  if(statut !== 'correcteur') {
    return (
      <div className={echauffementSelected !== 0 ? "" : "hide"}>
        <Fade left when={visible}>
          <div className="col-md-12">
            <div className="col-md-4 ">
              <button className="btn btn-success create" onClick={(e) => {window.scroll(0,0); boolShowEchauffement(showModalEchauffement)}}><i className="fas fa-plus faicon"></i>nouvelle échauffement</button>
            </div>
          </div>
        </Fade>
      </div>
    );
  } else {
    return (
      <div></div>
    )
  }
};

const Dashboard3 = ({showModalCoinMaternelles,boolShowCoinMaternelles,coinMaternellesSelected}) => {
  let visible = coinMaternellesSelected > 0 ? true : false

  var statut = cookies.get("statut")
  
  if(statut !== 'correcteur') {
    return (
      <div className={coinMaternellesSelected !== 0 ? "" : "hide"}>
        <Fade left when={visible}>
          <div className="col-md-12">
            <div className="col-md-4 ">
              <button className="btn btn-success create" onClick={(e) => {window.scroll(0,0); boolShowCoinMaternelles(showModalCoinMaternelles)}}><i className="fas fa-plus faicon"></i>nouveau coin</button>
            </div>
          </div>
        </Fade>
      </div>
    );
  } else {
    return (
      <div></div>
    )
  }
};

const SelectCycle = ({listeCycle,cycleSelected,buttonEchauffementSelected,buttonCoinMaternellesSelected,changeCycle,loadEchauffement,loadCoinMaternelles}) => {
  let listButton = []

  Array.from(listeCycle).forEach(value => {
    var cycle = value["nom"].split(' ')
    listButton.push(<div className="row" key={"cycles" + value["id_cycle"]}><button className={cycleSelected === value["id_cycle"] ? "btnCycleSelected" : "btnCycle"} onClick={(e) => {changeCycle(value["id_cycle"])}}><span className="cycleTitle">{cycle[0]}</span><span className="cycleNum">{cycle[1]}</span></button></div>)
  });

  let visible = listButton.length > 0 ? true : false

  return (
    <div className="col-md-12">
      <Fade left when={visible}>
        <div className="col-md-12 allCycle">
          {listButton}
          <div style={{'position':'absolute','right':'0'}}>
            <button className={cycleSelected === null && buttonEchauffementSelected === false ? "btnCycleSelected cycleTitle" : "btnCycle cycleTitle"} style={{'width':'auto'}} onClick={(e) => {loadCoinMaternelles()}}>Coin des Maternelles</button>
            <button className={cycleSelected === null && buttonCoinMaternellesSelected === false ? "btnCycleSelected cycleTitle" : "btnCycle cycleTitle"} style={{'width':'auto'}} onClick={(e) => {loadEchauffement()}}>Échauffement</button>
            <button className="btnCycle cycleTitle" onClick={(e) => {window.open("/image")}}>Image</button>
          </div>
        </div>
      </Fade>
    </div>
  )
}

const SelectCompetence = ({listeCompetence,competenceSelected,changeCompetence}) => {
  let listButton = []

  Array.from(listeCompetence).forEach(value => {
    listButton.push(<div className="row" key={"competence" + value["id_competence"]}><button className={competenceSelected === value["id_competence"] ? "cardCompetenceSelected" : "cardCompetence"} onClick={(e) => {changeCompetence(value["id_competence"])}}><div className="contentIcon"><img className="icon-competence" alt="icone" src={"/competence/"+value["nom_icone"]+".png"}/></div>{value["nom"]}</button></div>)
  });
  
  let visible = listButton.length > 0 ? true : false

  return (
    <div className="col-md-12">
      <Fade left when={visible}>
        <div className="col-md-12 allCycle">
          {listButton}
        </div>
      </Fade>
    </div>
  )
}

const SelectEchauffement = ({listeEchauffement,echauffementSelected,changeEchauffement}) => {
  let listButton = []

  Array.from(listeEchauffement).forEach(value => {
    listButton.push(<div className="row" key={"echauffement" + value["id"]}><button className={echauffementSelected === value["id"] ? "cardCompetenceSelected" : "cardCompetence"} onClick={(e) => {changeEchauffement(value["id"])}}><div className="contentIcon"><img className="icon-competence" alt="icone" src={"/echauffement/"+value["url_img"]+".png"}/></div>{value["nom"]}</button></div>)
  });
  
  let visible = listButton.length > 0 ? true : false

  return (
    <div className="col-md-12">
      <Fade left when={visible}>
        <div className="col-md-12 allCycle">
          {listButton}
        </div>
      </Fade>
    </div>
  )
}

const SelectCoinMaternelles = ({listeCoinMaternelles,coinMaternellesSelected,changeCoinMaternelles}) => {
  let listButton = []

  Array.from(listeCoinMaternelles).forEach(value => {
    listButton.push(<div className="row" key={"coin_maternelles" + value["id"]}><button className={coinMaternellesSelected === value["id"] ? "cardCompetenceSelected" : "cardCompetence"} onClick={(e) => {changeCoinMaternelles(value["id"])}}><div className="contentIcon"><img className="icon-competence" alt="icone" src={"/coin_maternelles/"+value["url_img"]+".png"}/></div>{value["nom"]}</button></div>)
  });
  
  let visible = listButton.length > 0 ? true : false

  return (
    <div className="col-md-12">
      <Fade left when={visible}>
        <div className="col-md-12 allCycle">
          {listButton}
        </div>
      </Fade>
    </div>
  )
}

const SelectSequence = ({listeSequence,sequenceSelected,changeSequence}) => {
  let listButton = []

  Array.from(listeSequence).forEach(value => {
    listButton.push(<div className="row displaySequence" style={{ background:`no-repeat url(${value["url_image"]})`, backgroundSize: 'cover', borderRadius: '10px' }} key={"sequence" + value["id_sequence"]}><button className={sequenceSelected === value["id_sequence"] ? "cardSequenceSelected" : "cardSequence"} onClick={(e) => {changeSequence(value["id_sequence"],value["nom"])}}><p className="textSequence">{value["nom"]}</p></button></div>)
  });

  let visible = listButton.length > 0 ? true : false

  return (
    <div className="col-md-12">
      <Fade left when={visible}>
        <div className="col-md-12 allCycle" style={{'overflow': 'auto'}}>
          {listButton}
        </div>
      </Fade>
    </div>
  )
}

const SelectCategorie = ({listeCategorie,categorieSelected,changeCategorie,showModal,boolShow,onClickHandler3,onChangeHandler3,routeFile}) => {
  let listButton = []

  Array.from(listeCategorie).forEach(value => {
    listButton.push(<div className="row buttonCategorie" key={"categorie" + value["id_categorie"]}><button onClick={(e) => {changeCategorie(value["id_categorie"])}} className={categorieSelected === value["id_categorie"] ? "cardCategorieSelected" : "cardCategorie"}></button><p className="">{value["nom"]}</p></div>)
  });

  let visible = listButton.length > 0 ? true : false
  let visible2 = categorieSelected === -1 ? true : false

  var value1 = routeFile + "1" + ".pdf"
  var route1 = "/content-application/mot_des_auteurs/" + value1

  var value2 = routeFile + "2" + ".pdf"
  var route2 = "/content-application/mot_des_auteurs/" + value2

  if (visible === true) {
    return (
      <div className={visible ? "col-md-12" : "col-md-12 hide" }>
        <Fade left when={visible}>
          <div className="col-md-12">
            <p><a href={route1} target="_blank">Mot des auteurs 1</a></p>
            <p><a href={route2} target="_blank">Mot des auteurs 2</a></p>
          </div>
          <div className="col-md-12 allCycle">
            {listButton}
          </div>
        </Fade>
      </div>
    );
  } else {
    return (
      <div className={visible2 ? "col-md-12" : "col-md-12 hide" }>
        <Fade left when={visible2}>
          <div className="col-md-12">
            <p><a href={route1} target="_blank">Mot des auteurs 1</a></p>
            <p><a href={route2} target="_blank">Mot des auteurs 2</a></p>
          </div>
          <div className="col-md-12">
            <div className="col-md-4 ">
              <button className="btn btn-success create" onClick={(e) => {window.scroll(0,0); boolShow(showModal)}}><i className="fas fa-plus faicon"></i>nouvelle activité</button>
            </div>
          </div>
        </Fade>
      </div>
    );
  } 
}

const DisplaySeance = ({detailSeance,showSeance,showCroquis,showMateriel,sequenceSelected,listeSequence,activiteSelected,displayCroquis,displayMateriel,displaySeance2,numBubble}) => {
  if(detailSeance) {
    let croquis = JSON.parse(detailSeance[0].croquis)
    let allCroquis = []
    let variable = JSON.parse(detailSeance[0].variable)
    let allVariable = []
    let materiel = JSON.parse(detailSeance[0].materiel)
    let allMateriel = []
    var key
    const content = detailSeance[0].descriptif

    for(key in croquis) {
      allCroquis.push(
        <div key={"modalCroquis"+key} style={{'marginTop': '80px'}}>
          <div className="consigneNumberLine">
            <img className="centerText imgModalWidth" alt="croquis" src={croquis[key]['croquis']} />
          </div>
          <div className="col-md-12">
            <p className="noMargin" style={{ 'textAlign': 'center', 'color': '#4CA9B3'}}>{croquis[key]['legende']}</p>
          </div>
        </div>
      )
    }

    for(key in variable) {
      allVariable.push(
        <div key={"modalVar"+key}>
          <div>
            <p className="noMargin">- {variable[key]}</p>
          </div>
        </div>
      )
    }

    for(key in materiel) {
      allMateriel.push(
        <div key={"ModalMat"+key} className='col-md-6'>
          <div className='col-md-12'>
            <a target={materiel[key]['materiel'].match(/.pdf/g) ? "_blank" : ""} href={materiel[key]['materiel'].match(/.pdf/g) ? materiel[key]['materiel'] : '#'}>
              <img className="noMargin" style={{'height':'100px','marginLeft':'auto','marginRight':'auto','display':'block'}} src={materiel[key]['materiel'].match(/.pdf/g) ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : materiel[key]['materiel']} alt="materiel"/>
            </a>
          </div>
          <div className="col-md-12">
            <p className="noMargin" style={{ 'textAlign': 'center', 'color': '#4CA9B3'}}>{materiel[key]['legende']}</p>
          </div>
        </div>
      )
    }

    return (
      <div className="fullWidth">
        <Fade left when={showSeance}>
          <div className={showSeance === true ? "fullWidth displayBlock" : "fullWidth displayNone"}>
            <div className="col-md-12 displaySeanceHeader">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Activité</span>
                    <span className="numBubble">{numBubble}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{detailSeance[0].titre_activite}</p>
                  <p className="titleActivityBubble2">Objectif: <span className="whiteText">{detailSeance[0].objectif}</span></p>
                </div>
                <div className="col-md-2 materielPicto" onClick={(e) => {displayMateriel()}}>
                  <div><img className="btnBottom2" alt="btn2" src="./btn2_white.png" /></div>
                </div>
            </div>
            <div className="col-md-12 displaySeanceBody">
              <div className="consigneNumberLine" style={{'display': 'block'}}>
                {ReactHtmlParser(content)}
              </div>
              <div className="divVariable" style={allVariable.length === 0 ? {'display':'none'} : {'display':'block'}}>
                <div className="col-md-12 contentDivVariable">
                  <span className="maintenanceImg"></span>
                  <p className="textTitleVariable">Variables</p>
                  <div className="col-md-12 allVariable">
                    {allVariable}
                  </div>
                </div>
              </div>
              <div className="bottomBar col-md-12">
                <div className="row contentHeight">
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayCroquis()}}>
                    <div><img className="btnBottom1" alt="btn1" src="./btn1.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayMateriel()}}>
                    <div><img className="btnBottom2" alt="btn2" src="./btn2.png" /></div>
                  </div>
                  <div className="col-md-4 noPadding3">
                    <div className="homeBtn"><img className="btnBottom3" alt="btn3" src="./btn3.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3">
                    <div><img className="btnBottom4" alt="btn4" src="./btn4.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displaySeance2()}}>
                    <div><img className="btnBottom5" alt="btn5" src="./btn5.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade left when={showCroquis}>
          <div className={showCroquis === true ? "fullWidth displayBlock" : "fullWidth displayNone"}>
            <div className="col-md-12 displaySeanceBody">
              <div className="col-md-12 displaySeanceHeaderMateriel">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Activité</span>
                    <span className="numBubble">{numBubble}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{detailSeance[0].titre_activite}</p>
                  <p className="titleActivityBubble2">Objectif: <span className="whiteText">{detailSeance[0].objectif}</span></p>
                </div>
                <div className="col-md-2 materielPicto" onClick={(e) => {displayMateriel()}}>
                  <div><img className="btnBottom2" alt="btn2" src="./btn2_white.png" /></div>
                </div>
              </div>
              <div className="topHeaderOrganisation">
                <span className="materielTitle">Organisation spatiale</span>
              </div>
              <div>
                {allCroquis}
              </div>
              <div className="bottomBar col-md-12">
                <div className="row contentHeight">
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayCroquis()}}>
                    <div><img className="btnBottom1" alt="btn1" src="./btn1.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayMateriel()}}>
                    <div><img className="btnBottom2" alt="btn2" src="./btn2.png" /></div>
                  </div>
                  <div className="col-md-4 noPadding3">
                    <div className="homeBtn"><img className="btnBottom3" alt="btn3" src="./btn3.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3">
                    <div><img className="btnBottom4" alt="btn4" src="./btn4.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displaySeance2()}}>
                    <div><img className="btnBottom5" alt="btn5" src="./btn5.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade left when={showMateriel}>
          <div className={showMateriel === true ? "fullWidth displayBlock" : "fullWidth displayNone"}>
            <div className="col-md-12 displaySeanceBody row">
              <div className="col-md-12 displaySeanceHeaderMateriel">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Activité</span>
                    <span className="numBubble">{numBubble}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{detailSeance[0].titre_activite}</p>
                  <p className="titleActivityBubble2">Objectif: <span className="whiteText">{detailSeance[0].objectif}</span></p>
                </div>
                <div className="col-md-2 materielPicto" onClick={(e) => {displayMateriel()}}>
                  <div><img className="btnBottom2" alt="btn2" src="./btn2_white.png" /></div>
                </div>
              </div>
              <div className="topHeaderMateriel">
                <img className="materielTitleImg" src="btn2_blue.png" alt="btn2blue" />
                <span className="materielTitle">Le matériel</span>
              </div>
              {allMateriel}
              <div className="bottomBar col-md-12">
                <div className="row contentHeight">
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayCroquis()}}>
                    <div><img className="btnBottom1" alt="btn1" src="./btn1.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displayMateriel()}}>
                    <div><img className="btnBottom2" alt="btn2" src="./btn2.png" /></div>
                  </div>
                  <div className="col-md-4 noPadding3">
                    <div className="homeBtn"><img className="btnBottom3" alt="btn3" src="./btn3.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3">
                    <div><img className="btnBottom4" alt="btn4" src="./btn4.png" /></div>
                  </div>
                  <div className="col-md-2 noPadding3" onClick={(e) => {displaySeance2()}}>
                    <div><img className="btnBottom5" alt="btn5" src="./btn5.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  } else {
    return (<div className=""></div>);
  }
};

const DisplayEchauffement = ({detailSeanceEchauffement,showEchauffement2,selectedEchauffement,numBubble}) => {
  if(detailSeanceEchauffement) {
    let variable = JSON.parse(detailSeanceEchauffement[0].variable)
    let allVariable = []

    var key
    const content = detailSeanceEchauffement[0].descriptif

    for(key in variable) {
      allVariable.push(
        <div key={"modalVar"+key}>
          <div>
            <p className="noMargin">- {variable[key]}</p>
          </div>
        </div>
      )
    }

    return (
      <div className="fullWidth">
        <Fade left when={showEchauffement2}>
          <div className={showEchauffement2 === true ? "fullWidth displayBlock" : "fullWidth displayNone"}>
            <div className="col-md-12 displaySeanceHeader">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Echauffement</span>
                    <span className="numBubble">{numBubble}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{detailSeanceEchauffement[0].titre_activite}</p>
                </div>
            </div>
            <div className="col-md-12 displaySeanceBody">
              <div className="consigneNumberLine" style={{'display': 'block'}}>
                {ReactHtmlParser(content)}
              </div>
              <div className="divVariable" style={allVariable.length === 0 ? {'display':'none'} : {'display':'block'}}>
                <div className="col-md-12 contentDivVariable">
                  <span className="maintenanceImg"></span>
                  <p className="textTitleVariable">Variables</p>
                  <div className="col-md-12 allVariable">
                    {allVariable}
                  </div>
                </div>
              </div>
              <div className="bottomBar col-md-12">
                <div className="row contentHeight">
                  <div className="col-md-4 noPadding3">
                    <div className="homeBtn" style={{ 'marginLeft' : '145px' }}><img className="btnBottom3" alt="btn3" src="./btn3.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  } else {
    return (<div className=""></div>);
  }
};

const DisplayCoinMaternelles = ({detailSeanceCoinMaternelles,showCoinMaternelles2,selectedCoinMaternelles,numBubble}) => {
  if(detailSeanceCoinMaternelles) {
    let variable = JSON.parse(detailSeanceCoinMaternelles[0].variable)
    let allVariable = []

    var key
    const content = detailSeanceCoinMaternelles[0].descriptif

    for(key in variable) {
      allVariable.push(
        <div key={"modalVar"+key}>
          <div>
            <p className="noMargin">- {variable[key]}</p>
          </div>
        </div>
      )
    }

    return (
      <div className="fullWidth">
        <Fade left when={showCoinMaternelles2}>
          <div className={showCoinMaternelles2 === true ? "fullWidth displayBlock" : "fullWidth displayNone"}>
            <div className="col-md-12 displaySeanceHeader">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Coin des maternelles</span>
                    <span className="numBubble">{numBubble}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{detailSeanceCoinMaternelles[0].titre_activite}</p>
                </div>
            </div>
            <div className="col-md-12 displaySeanceBody">
              <div className="consigneNumberLine" style={{'display': 'block'}}>
                {ReactHtmlParser(content)}
              </div>
              <div className="divVariable" style={allVariable.length === 0 ? {'display':'none'} : {'display':'block'}}>
                <div className="col-md-12 contentDivVariable">
                  <span className="maintenanceImg"></span>
                  <p className="textTitleVariable">Variables</p>
                  <div className="col-md-12 allVariable">
                    {allVariable}
                  </div>
                </div>
              </div>
              <div className="bottomBar col-md-12">
                <div className="row contentHeight">
                  <div className="col-md-4 noPadding3">
                    <div className="homeBtn" style={{ 'marginLeft' : '145px' }}><img className="btnBottom3" alt="btn3" src="./btn3.png" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    )
  } else {
    return (<div className=""></div>);
  }
};

const NewSeanceModal = ({key,initialValue,csvMateriel,content,handleEditorChange,showModal,boolShow,nbMateriel,nbVariable,nbCroquis,listMateriel,listVariable,nbMaterielInc,nbVariableInc,nbCroquisInc,createSeance,checkonChange,onDrop,onDrop2,changeCroquis,croquisSected}) => {
  let objectif
  let inputMateriel = []
  let inputVariable = []
  let inputCroquis = []
  var i = 0
  let titreActivite = ''

  for(i=0;nbMateriel > i; i++) {
    inputMateriel.push(<div key={"materiel"+i}><img src={csvMateriel[i] !== undefined ? (csvMateriel[i]['materiel'].match(/.pdf/g) ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : csvMateriel[i]['materiel'] !== "" ? csvMateriel[i]['materiel'] : "https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg" ) : 'https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg'} className="materielInput imageMateriel" alt="materiel" /><input type="text" name={i} placeholder={"Matériel " + (i+1)} className="form-control inputFile materielInput" onChange={(e) => {listMateriel(e.target.name, e.target.value, 'materiel')}} /><input type="text" name={i} placeholder={"Légende " + (i+1)} className="form-control inputFile materielInput" onChange={(e) => {listMateriel(e.target.name, e.target.value, 'legende')}} /></div>)
  }

  for(i=0;nbVariable > i; i++) {
    inputVariable.push(<div style={{ 'display': 'flex'}}><input key={"variable"+i} type="text" name={i} placeholder={"Variable " + (i+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,-1)}}>X</button></div>)
  }

  for(i=0;nbCroquis > i; i++) {
    inputCroquis.push(<div key={"croquis" + i}><img src={croquisSected[i] !== undefined ? croquisSected[i]['croquis'] !== "" ? croquisSected[i]['croquis'] : "https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg" : "https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg"} className="materielInput imageMateriel" alt="croquis" /><input type="text" name={i} placeholder={"Croquis"} className="form-control inputFile materielInput" onChange={(e) => {changeCroquis(e.target.name, e.target.value, 'croquis')}} /><input type="text" name={i} placeholder={"Légende"} className="form-control inputFile materielInput" onChange={(e) => {changeCroquis(e.target.name, e.target.value, 'legende')}} /><button className="btn btn-danger btn-in-modal" onClick={(e) => {nbCroquisInc(nbCroquis,-1)}}>X</button></div>)
  }

  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog key="editor12" show={showModal} className={showModal === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShow(true);}}>
        <Modal.Header>
          <Modal.Title>Nouvelle activité</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" placeholder="Titre activité" ref={titreActiviteValue => {
              titreActivite = titreActiviteValue
            }}/>
            <textarea key={key + 'objectif'} type="text" className="form-control textArea1" placeholder="Objectif" ref={objectifValue => {
              objectif = objectifValue
            }}/>
            <br />
            Description:
            <div>

              <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                initialValue='Nouveau'
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                    { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               // onEditorChange={(e) => { contentValue = e}}
             />
            </div>
            <br />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,1)}}>Ajouter une variable</button>
            </div>
            <div>
              Croquis: {inputCroquis}
              <button className="btn btn-success btn-in-modal" onClick={(e) => {nbCroquisInc(nbCroquis,1)}}>Ajouter un croquis</button>
            </div>
            <div>
              Matériel: {inputMateriel}
            </div>
            <button className="btn btn-success btn-in-modal" onClick={(e) => {nbMaterielInc(nbMateriel,1)}}>Ajouter un matériel</button>
            <button className="btn btn-danger btn-in-modal" onClick={(e) => {nbMaterielInc(nbMateriel,-1)}}>Retirer un matériel</button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { createSeance(titreActivite.value,objectif.value,content) }}>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShow(showModal); }}>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};


const NewSeanceModal2 = ({key,initialValue,content,handleEditorChange,showModalEchauffement,boolShowEchauffement,nbVariable,listVariable,nbVariableInc,createEchauffement,checkonChange,onDrop,onDrop2}) => {
  let objectif
  let inputVariable = []
  var i = 0
  let titreActivite = ''

  for(i=0;nbVariable > i; i++) {
    inputVariable.push(<div style={{ 'display': 'flex'}}><input key={"variable"+i} type="text" name={i} placeholder={"Variable " + (i+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,-1)}}>X</button></div>)
  }

  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog key="editor12" show={showModalEchauffement} className={showModalEchauffement === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShowEchauffement(true);}}>
        <Modal.Header>
          <Modal.Title>Nouvel échauffement</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" placeholder="Titre échauffement" ref={titreActiviteValue => {
              titreActivite = titreActiviteValue
            }}/>
            <br />
            Description:
            <div>

              <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                initialValue='Nouveau'
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                    { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               // onEditorChange={(e) => { contentValue = e}}
             />
            </div>
            <br />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,1)}}>Ajouter une variable</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { createEchauffement(titreActivite.value,content) }}>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShowEchauffement(showModalEchauffement); }}>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const NewSeanceModal3 = ({key,initialValue,content,handleEditorChange,showModalCoinMaternelles,boolShowCoinMaternelles,nbVariable,listVariable,nbVariableInc,createCoinMaternelles,checkonChange,onDrop,onDrop2}) => {
  let objectif
  let inputVariable = []
  var i = 0
  let titreActivite = ''

  for(i=0;nbVariable > i; i++) {
    inputVariable.push(<div style={{ 'display': 'flex'}}><input key={"variable"+i} type="text" name={i} placeholder={"Variable " + (i+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,-1)}}>X</button></div>)
  }

  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog key="editor12" show={showModalCoinMaternelles} className={showModalCoinMaternelles === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShowCoinMaternelles(true);}}>
        <Modal.Header>
          <Modal.Title>Nouveau coin</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" placeholder="Titre coin" ref={titreActiviteValue => {
              titreActivite = titreActiviteValue
            }}/>
            <br />
            Description:
            <div>

              <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                initialValue='Nouveau'
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                    { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               // onEditorChange={(e) => { contentValue = e}}
             />
            </div>
            <br />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => {nbVariableInc(nbVariable,1)}}>Ajouter une variable</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { createCoinMaternelles(titreActivite.value,content) }}>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShowCoinMaternelles(showModalCoinMaternelles); }}>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const ModifSeance = ({key,initialValue,content,handleEditorChange,modifId,titre_activite,objectif,csvMateriel,csvVariable,showModal3,boolShow3,nbMateriel,nbVariable,nbCroquis,listMateriel,listVariable,nbMaterielInc,nbVariableInc,nbCroquisInc,modifSeance,checkonChange,onDrop,onDrop2,croquisSected,changeCroquis}) => {
  let inputMateriel = []
  let inputVariable = []
  let inputCroquis = []
  var i
  let contentValue = ''

  let tmpTitreActivite = titre_activite
  let tmpObjectif = objectif

  Object.keys(csvMateriel).map((key, index) => (
    inputMateriel.push(<div key={"materiel"+index} style={{ 'display': 'flex'}}><img src={csvMateriel[key] !== undefined ? (csvMateriel[key]['materiel'].match(/.pdf/g) ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg' : csvMateriel[key]['materiel']) : 'https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg'} className="materielInput imageMateriel" alt="materiel" /><input type="text" name={key} value={csvMateriel[key]['materiel']} placeholder={"Matériel " + (index+1)} className="form-control inputFile materielInput" onChange={(e) => {listMateriel(e.target.name, e.target.value, 'materiel')}} /><input type="text" value={csvMateriel[key]['legende']} name={key} placeholder={"Légende " + (index+1)} className="form-control inputFile materielInput" onChange={(e) => {listMateriel(e.target.name, e.target.value, 'legende')}} /><button className="btn btn-danger btn-in-modal" name={key} onClick={(e) => {nbMaterielInc(nbMateriel,-1, e.target.name)}}>X</button></div>)
  ));

  Object.keys(csvVariable).map((key, index) => (
    inputVariable.push(<div key={"variable"+index} style={{ 'display': 'flex'}}><input type="text" name={key} value={csvVariable[key]} placeholder={"Variable " + (index+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" name={key} onClick={(e) => {nbVariableInc(nbVariable,-1, e.target.name)}}>X</button></div>)
  ));

  Object.keys(croquisSected).map((key, index) => (
    inputCroquis.push(<div key={"croquis"+index}><img src={croquisSected[key] !== undefined ? croquisSected[key]['croquis'] !== "" ? croquisSected[key]['croquis'] : "https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg" : "https://data.pixiz.com/output/user/frame/preview/api/big/5/6/1/0/2700165_4275d.jpg"} className="materielInput imageMateriel" alt="croquis" /><input type="text" name={key} placeholder={"Croquis"} value={croquisSected[key] !== undefined ? croquisSected[key]['croquis'] : '' || ''} className="form-control inputFile materielInput" onChange={(e) => {changeCroquis(e.target.name, e.target.value, 'croquis')}} /><input type="text" name={key} placeholder={"Légende"} value={croquisSected[key] !== undefined ? croquisSected[key]['legende'] : '' || ''} className="form-control inputFile materielInput" onChange={(e) => {changeCroquis(e.target.name, e.target.value, 'legende')}} /><button className="btn btn-danger btn-in-modal" name={key} onClick={(e) => {nbCroquisInc(nbCroquis,-1, e.target.name)}}>X</button></div>)
  ));

  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog show={showModal3} className={showModal3 === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShow3(true)}}>
        <Modal.Header>
          <Modal.Title>Modification activité</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" defaultValue={tmpTitreActivite} onChange={(e) => {tmpTitreActivite = e.target.value}} placeholder="Titre activité" ref={titreActiviteValue => {
              tmpTitreActivite = titreActiviteValue
            }}/>
            <textarea key={key + 'objectif'} type="text" className="form-control textArea1" defaultValue={tmpObjectif} onChange={(e) => {tmpObjectif = e.target.value}} placeholder="Objectif" ref={objectifValue => {
              tmpObjectif = objectifValue
            }}/>
            <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                value={content}
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               //onEditorChange={(e) => { contentValue = e }}
             />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => { nbVariableInc(nbVariable,1)} }>Ajouter une variable</button>
            </div>
            Croquis: 
            <div className="row">
              { inputCroquis }
              <button className="btn btn-success btn-in-modal" onClick={(e) => { nbCroquisInc(nbCroquis,1)} }>Ajouter un croquis</button>
            </div>
            <div>
              Matériel: {inputMateriel}
            </div>
            <button className="btn btn-success btn-in-modal" onClick={(e) => { nbMaterielInc(nbMateriel,1)} }>Ajouter un matériel</button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { modifSeance(modifId,tmpTitreActivite.value === undefined ? tmpTitreActivite : tmpTitreActivite.value,tmpObjectif.value === undefined ? tmpObjectif : tmpObjectif.value, content)} }>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShow3(showModal3)} }>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const ModifSeance2 = ({key,initialValue,content,handleEditorChange,modifId,titre_activite,csvVariable,boolShow2Echauffement,showModalEchauffement2,nbVariable,listVariable,nbVariableInc,modifSeance2,checkonChange,onDrop,onDrop2}) => {
  let inputVariable = []

  var i
  let contentValue = ''

  let tmpTitreActivite = titre_activite

  Object.keys(csvVariable).map((key, index) => (
    inputVariable.push(<div key={"variable"+index} style={{ 'display': 'flex'}}><input type="text" name={key} value={csvVariable[key]} placeholder={"Variable " + (index+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" name={key} onClick={(e) => {nbVariableInc(nbVariable,-1, e.target.name)}}>X</button></div>)
  ));


  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog show={showModalEchauffement2} className={showModalEchauffement2 === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShow2Echauffement(true)}}>
        <Modal.Header>
          <Modal.Title>Modification échauffement</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" defaultValue={tmpTitreActivite} onChange={(e) => {tmpTitreActivite = e.target.value}} placeholder="Titre activité" ref={titreActiviteValue => {
              tmpTitreActivite = titreActiviteValue
            }}/>
            <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                value={content}
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               //onEditorChange={(e) => { contentValue = e }}
             />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => { nbVariableInc(nbVariable,1)} }>Ajouter une variable</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { modifSeance2(modifId,tmpTitreActivite.value === undefined ? tmpTitreActivite : tmpTitreActivite.value, content)} }>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShow2Echauffement(showModalEchauffement2)} }>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const ModifSeance3 = ({key,initialValue,content,handleEditorChange,modifId,titre_activite,csvVariable,boolShow2CoinMaternelles,showModalCoinMaternelles2,nbVariable,listVariable,nbVariableInc,modifSeance3,checkonChange,onDrop,onDrop2}) => {
  let inputVariable = []

  var i
  let contentValue = ''

  let tmpTitreActivite = titre_activite

  Object.keys(csvVariable).map((key, index) => (
    inputVariable.push(<div key={"variable"+index} style={{ 'display': 'flex'}}><input type="text" name={key} value={csvVariable[key]} placeholder={"Variable " + (index+1)} className="form-control inputFile" onChange={(e) => {listVariable(e.target.name, e.target.value)}} /><button className="btn btn-danger btn-in-modal" name={key} onClick={(e) => {nbVariableInc(nbVariable,-1, e.target.name)}}>X</button></div>)
  ));


  return (
    <div className="static-modal" key={key}>
      <Modal.Dialog show={showModalCoinMaternelles2} className={showModalCoinMaternelles2 === false ? 'hide' : 'show specialPopUp'} onHide={(e) => {boolShow2CoinMaternelles(true)}}>
        <Modal.Header>
          <Modal.Title>Modification coin</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 form-container">
            <input key={key + 'titre'} type="text" className="form-control" defaultValue={tmpTitreActivite} onChange={(e) => {tmpTitreActivite = e.target.value}} placeholder="Titre activité" ref={titreActiviteValue => {
              tmpTitreActivite = titreActiviteValue
            }}/>
            <Editor
                apiKey="umktbzktl189dztfwxfm6tfjx53ygerulcbzjubxsnsn3vv9"
                value={content}
                init={{
                  formats : {
                    underline : {inline : 'u', exact : true}
                  },
                  height: 500,
                  menubar: false,
                  selector: 'textarea#full-featured-non-premium',
                  plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help',
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | customInsertButton customInsertButton2 customInsertButton3 customInsertButton4 customInsertButton5 customInsertButton6',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'http://www.tinymce.com' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customInsertButton', {
                      text: 'ℹ️',
                      onAction: function (_) {
                        editor.insertContent('<button title="CHANGE CE TEXTE">ℹ️</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton2', {
                      text: '⚽',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_materiel">⚽</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton3', {
                      text: '💡',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_defi">💡</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton4', {
                      text: '📈',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_up">📈</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton5', {
                      text: '🏆',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_win">🏆</button>');
                      }
                    });

                    editor.ui.registry.addButton('customInsertButton6', {
                      text: '💬',
                      onAction: function (_) {
                        editor.insertContent('<button title="id_speach">💬</button>');
                      }
                    });
                  },
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                    }
                  },
                  templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table'
               }}
               onEditorChange={(e) => {handleEditorChange(e)}}
               //onEditorChange={(e) => { contentValue = e }}
             />
            <div>
              Variable: {inputVariable}
              <button className="btn btn-success btn-in-modal" onClick={(e) => { nbVariableInc(nbVariable,1)} }>Ajouter une variable</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={(e) => { modifSeance3(modifId,tmpTitreActivite.value === undefined ? tmpTitreActivite : tmpTitreActivite.value, content)} }>valider</Button>
          <Button className="btn btn-danger" onClick={(e) => { boolShow2CoinMaternelles(showModalCoinMaternelles2)} }>annuler</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};


const AllSeance = ({allSeance1,displaySeance,showSeance,deleteSeance,boolShow3,showModal3,loadDetailSeance,copyCodeToClipboard}) => {
  let allRow = {}

  var statut = cookies.get("statut")

  if(allSeance1) {
    let countRow = (allSeance1.length - 1)
    allRow = allSeance1.map((id,counter) => {
      return (
        <div key={id.id} className="col-md-12 noMargin">
          <div className="col-md-12 card-style">
            <div className={counter % 2 === 0 ? "cardActivite" : "cardActivite2"} onClick={(e) => {e.stopPropagation(); displaySeance(showSeance,id.id,counter+ 1)}}>
              <div className="col-md-12 flexDiv">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Activité</span>
                    <span className="numBubble">{counter + 1}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{id.titre_activite}</p>
                  <p className="titleActivityBubble2">Objectif: <span className="whiteText">{id.objectif}</span></p>
                </div>
              </div>
              <div className={countRow === counter ? "div-button activiteCard" : "div-button activiteCard"}>
                <button onClick={(e) => {window.scroll(0,0); e.stopPropagation(); boolShow3(false,counter,id.id)}} className={ statut !== 'correcteur' ? "btn btn-success marginAuto" : "hide"}>modifier</button>
                <button onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ?')===true){deleteSeance(id.id)}}} className={ statut !== 'correcteur' ? "btn btn-danger marginAuto" : "hide" }>supprimer</button>
                <button onClick={(e) => {e.stopPropagation(); copyCodeToClipboard("https://eps.acces-editions.com/api/getDetailSeance?id=" + id.id)}} className={ "btn btn-primary marginAuto" }>lien</button>
              </div>
            </div>
          </div>
        </div>
      )
    });
  }

  return allRow;
};

const AllSeance2 = ({listeEchauffementActivite,displayEchauffement,showEchauffement2,deleteEchauffement,boolShow2Echauffement,showModalEchauffement2,loadDetailSeance,copyCodeToClipboard}) => {
  let allRow = {}

  var statut = cookies.get("statut")

  if(listeEchauffementActivite) {
    let countRow = (listeEchauffementActivite.length - 1)
    allRow = listeEchauffementActivite.map((id,counter) => {
      return (
        <div key={id.id + "_echauffement"} className="col-md-12 noMargin">
          <div className="col-md-12 card-style">
            <div className={counter % 2 === 0 ? "cardActivite" : "cardActivite2"} onClick={(e) => {e.stopPropagation(); displayEchauffement(showEchauffement2,id.id,counter+ 1)}}>
              <div className="col-md-12 flexDiv">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Echauffement</span>
                    <span className="numBubble">{counter + 1}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{id.titre_activite}</p>
                </div>
              </div>
              <div className={countRow === counter ? "div-button activiteCard" : "div-button activiteCard"}>
                <button onClick={(e) => {window.scroll(0,0); e.stopPropagation(); boolShow2Echauffement(false,counter,id.id)}} className={ statut !== 'correcteur' ? "btn btn-success marginAuto" : "hide"}>modifier</button>
                <button onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ?')===true){deleteEchauffement(id.id)}}} className={ statut !== 'correcteur' ? "btn btn-danger marginAuto" : "hide" }>supprimer</button>
                <button onClick={(e) => {e.stopPropagation(); copyCodeToClipboard("https://eps.acces-editions.com/api/getEchauffementActivite?id=" + id.id)}} className={ "btn btn-primary marginAuto" }>lien</button>
              </div>
            </div>
          </div>
        </div>
      )
    });
  }

  return allRow;
};

const AllSeance3 = ({listeCoinMaternellesActivite,displayCoinMaternelles,showCoinMaternelles2,deleteCoinMaternelles,boolShow2CoinMaternelles,showModalCoinMaternelles2,loadDetailSeance,copyCodeToClipboard}) => {
  let allRow = {}

  var statut = cookies.get("statut")

  if(listeCoinMaternellesActivite) {
    let countRow = (listeCoinMaternellesActivite.length - 1)
    allRow = listeCoinMaternellesActivite.map((id,counter) => {
      return (
        <div key={id.id + "_coin_maternelles"} className="col-md-12 noMargin">
          <div className="col-md-12 card-style">
            <div className={counter % 2 === 0 ? "cardActivite" : "cardActivite2"} onClick={(e) => {e.stopPropagation(); displayCoinMaternelles(showCoinMaternelles2,id.id,counter+ 1)}}>
              <div className="col-md-12 flexDiv">
                <div className="titleAtelier">
                  <p className="labelActivite">
                    <span className="titleBubble">Coin</span>
                    <span className="numBubble">{counter + 1}</span>
                  </p>
                </div>
                <div className="titleActivityBubble">
                  <p className="titleActivityBubble1">{id.titre_activite}</p>
                </div>
              </div>
              <div className={countRow === counter ? "div-button activiteCard" : "div-button activiteCard"}>
                <button onClick={(e) => {window.scroll(0,0); e.stopPropagation(); boolShow2CoinMaternelles(false,counter,id.id)}} className={ statut !== 'correcteur' ? "btn btn-success marginAuto" : "hide"}>modifier</button>
                <button onClick={(e) => {e.stopPropagation(); if(window.confirm('supprimer ?')===true){deleteCoinMaternelles(id.id)}}} className={ statut !== 'correcteur' ? "btn btn-danger marginAuto" : "hide" }>supprimer</button>
                <button onClick={(e) => {e.stopPropagation(); copyCodeToClipboard("https://eps.acces-editions.com/api/getCoinMaternellesActivite?id=" + id.id)}} className={ "btn btn-primary marginAuto" }>lien</button>
              </div>
            </div>
          </div>
        </div>
      )
    });
  }

  return allRow;
};

class DashboardClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModalEchauffement: false,
      showModalEchauffement2: false,
      showModalCoinMaternelles: false,
      showModalCoinMaternelles2: false,
      showSeance: false,
      showEchauffement2: false,
      showCoinMaternelles2: false,
      showCroquis: false,
      showMateriel: false,
      checked1: true,
      checked2: false,
      allSeance1: [],
      allSeance2: [],
      idUpdateModal: null,
      idContenuModal: null,
      nbMateriel: 1,
      nbVariable: 1,
      nbCroquis: 1,
      csvMateriel: {},
      csvVariable: {},
      pictures: [],
      pictures2: [],
      selectedFile: [],
      objectif: "",
      nameAtelier: "",
      descriptif: "",
      organisation: {},
      organisationDelete: [],
      listeCycle: {},
      listeCompetence: {},
      listeSequence: {},
      listeCategorie: {},
      listeEchauffement: {},
      listeEchauffementActivite: [],
      listeCoinMaternelles: {},
      listeCoinMaternellesActivite: [],
      cycleSelected: 0,
      competenceSelected: 0,
      echauffementSelected: 0,
      coinMaternellesSelected: 0,
      selectedEchauffement: null,
      selectedCoinMaternelles: null,
      sequenceSelected: 0,
      categorieSelected: 0,
      activiteSelected: "",
      selectedSeance: null,
      titre: "",
      titre_activite: "",
      modifId: null,
      identifiant: "",
      content: '',
      croquisSected: {},
      initialValue: 'Nouveau',
      buttonCoinMaternellesSelected: false,
      buttonEchauffementSelected: false,
      key: Math.random(),
      key2: Math.random(),
      key3: Math.random(),
      key4: Math.random(),
      key5: Math.random(),
      key6: Math.random()
    }
    this.onDrop = this.onDrop.bind(this);
    this.onDrop2 = this.onDrop2.bind(this);
  }

  handleEditorChange(content) {
    this.setState({content:content.trim()})
  }

  onDrop(picture) {
    this.setState({
        pictures: picture,
    });
  }

  onDrop2(picture) {
    this.setState({
        pictures2: picture,
    });
  }

  boolShow(value) {
    this.setState({ 
      showModal: !value,
      nbVariable: 1,
      nbMateriel: 1,
      nbCroquis: 1,
      csvMateriel: {},
      csvVariable: {},
      organisation: {},
      key: Math.random()
    })
  }

  boolShowEchauffement(value) {
    this.setState({ 
      showModalEchauffement: !value,
      nbVariable: 1,
      nbMateriel: 1,
      nbCroquis: 1,
      csvMateriel: {},
      csvVariable: {},
      organisation: {},
      key: Math.random(),
      key2: Math.random(),
      key3: Math.random(),
      key4: Math.random()
    })
  }

  boolShowCoinMaternelles(value) {
    this.setState({ 
      showModalCoinMaternelles: !value,
      nbVariable: 1,
      nbMateriel: 1,
      nbCroquis: 1,
      csvMateriel: {},
      csvVariable: {},
      organisation: {},
      key: Math.random(),
      key2: Math.random(),
      key3: Math.random(),
      key4: Math.random()
    })
  }

  boolShow1(value,id) {
    this.setState({ 
      showModal1: !value,
      idUpdateModal: id
    })
  }

  boolShow2(value,id) {
      this.setState({ 
        showModal2: !value,
        idContenuModal: id,
        nbMateriel: 1,
      })
  }

  copyCodeToClipboard(lien) {
    window.prompt("Lien symbolique", lien);
  }

  async boolShow3(value,id,id_seance) {
    if(value === false) {
      await this.loadDetailSeance(id_seance)

      let croquis = JSON.parse(this.state.detailSeance[0].croquis)
      let variable = JSON.parse(this.state.detailSeance[0].variable)
      let materiel = JSON.parse(this.state.detailSeance[0].materiel)
      let descriptif = this.state.detailSeance[0].descriptif

      let titre = this.state.detailSeance[0].titre
      let titre_activite = this.state.detailSeance[0].titre_activite
      let objectif = this.state.detailSeance[0].objectif

      let nbMateriel = Object.keys(materiel).length
      let nbVariable = Object.keys(variable).length
      let nbCroquis = Object.keys(croquis).length

      this.setState({
        modifId: id_seance,
        showModal3: !value,
        idContenuModal: id,
        csvMateriel: materiel,
        csvVariable: variable,
        nbMateriel: nbMateriel,
        nbVariable: nbVariable,
        nbCroquis: nbCroquis,
        croquisSected: croquis,
        titre: titre,
        titre_activite: titre_activite,
        objectif: objectif,
        content: descriptif,
        key: Math.random(),
        key2: Math.random(),
        key3: Math.random(),
        key4: Math.random()
      })
    } else {
      this.setState({ 
        showModal3: !value

      })
    }
  }

  async boolShow2Echauffement(value,id,id_seance) {
    if(value === false) {
      await this.loadDetailSeanceEchauffement(id_seance)

      let variable = JSON.parse(this.state.detailSeanceEchauffement[0].variable)
      let descriptif = this.state.detailSeanceEchauffement[0].descriptif

      let titre_activite = this.state.detailSeanceEchauffement[0].titre_activite

      let nbVariable = Object.keys(variable).length

      this.setState({
        modifId: id_seance,
        showModalEchauffement2: !value,
        idContenuModal: id,
        nbVariable: nbVariable,
        csvVariable: variable,
        titre_activite: titre_activite,
        content: descriptif,
        key: Math.random(),
        key2: Math.random(),
        key3: Math.random(),
        key4: Math.random()
      })
    } else {
      this.setState({ 
        showModalEchauffement2: !value
      })
    }
  }

  async boolShow2CoinMaternelles(value,id,id_seance) {
    if(value === false) {
      await this.loadDetailSeanceCoinMaternelles(id_seance)

      let variable = JSON.parse(this.state.detailSeanceCoinMaternelles[0].variable)
      let descriptif = this.state.detailSeanceCoinMaternelles[0].descriptif

      let titre_activite = this.state.detailSeanceCoinMaternelles[0].titre_activite

      let nbVariable = Object.keys(variable).length

      this.setState({
        modifId: id_seance,
        showModalCoinMaternelles2: !value,
        idContenuModal: id,
        nbVariable: nbVariable,
        csvVariable: variable,
        titre_activite: titre_activite,
        content: descriptif,
        key: Math.random(),
        key2: Math.random(),
        key3: Math.random(),
        key4: Math.random()
      })
    } else {
      this.setState({ 
        showModalCoinMaternelles2: !value
      })
    }
  }

  async boolShow4(value,id,id_seance) {
    await this.loadDetailSeance(id_seance)
    this.setState({ 
      showModal4: !value,
      idContenuModal: id
    })
  }

  async displaySeance(value,id_seance,counter) {
    await this.loadDetailSeance(id_seance)
    if(id_seance !== this.state.selectedSeance) {
      this.setState({
        selectedSeance: id_seance,
        showSeance: true,
        showCroquis: false,
        showMateriel: false,
        numBubble: counter
      })
    } else {
      this.setState({
        selectedSeance: null,
        showSeance: false,
        showCroquis: false,
        showMateriel: false,
        numBubble: counter
      })
    }
  }

  async displayEchauffement(value,id_seance,counter) {
    await this.loadDetailSeanceEchauffement(id_seance)
    if(id_seance !== this.state.selectedEchauffement) {
      this.setState({
        selectedEchauffement: id_seance,
        showEchauffement2: true,
        numBubble: counter
      })
    } else {
      this.setState({
        selectedEchauffement: null,
        showEchauffement2: false,
        numBubble: counter
      })
    }
  }

  async displayCoinMaternelles(value,id_seance,counter) {
    await this.loadDetailSeanceCoinMaternelles(id_seance)
    if(id_seance !== this.state.selectedCoinMaternelles) {
      this.setState({
        selectedCoinMaternelles: id_seance,
        showCoinMaternelles2: true,
        numBubble: counter
      })
    } else {
      this.setState({
        selectedCoinMaternelles: null,
        showCoinMaternelles2: false,
        numBubble: counter
      })
    }
  }

  displaySeance2() {
    this.setState({
      showSeance: true,
      showCroquis: false,
      showMateriel: false
    })
  }

  displayCroquis() {
    this.setState({
      showSeance: false,
      showCroquis: true,
      showMateriel: false
    })
  }
  
  displayMateriel() {
    this.setState({
      showSeance: false,
      showCroquis: false,
      showMateriel: true
    })
  }

  changeEchauffement(id) {
    if(id !== this.state.echauffementSelected) {
      this.setState({
        cycleSelected: null,
        competenceSelected: 0,
        echauffementSelected: id,
        coinMaternellesSelected: 0,
        sequenceSelected: 0,
        categorieSelected: 0,
        allSeance1:[],
        allSeance2: [],
        listeEchauffementActivite: [],
        listeCoinMaternelles: {},
        listeCoinMaternellesActivite: [],
        listeCompetence: {},
        listeSequence: {},
        listeCategorie: {},
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      }, () => {
        this.loadEchauffementActivite()
      })
    }
  }

  changeCoinMaternelles(id) {
    if(id !== this.state.coinMaternellesSelected) {
      this.setState({
        cycleSelected: null,
        competenceSelected: 0,
        echauffementSelected: 0,
        coinMaternellesSelected: id,
        sequenceSelected: 0,
        categorieSelected: 0,
        allSeance1:[],
        allSeance2: [],
        listeEchauffement: {},
        listeEchauffementActivite: [],
        listeCoinMaternellesActivite: [],
        listeCompetence: {},
        listeSequence: {},
        listeCategorie: {},
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      }, () => {
        this.loadCoinMaternellesActivite()
      })
    }
  }

  changeCycle(id) {
    if(id !== this.state.cycleSelected) {
      this.setState({
        cycleSelected: id,
        competenceSelected: 0,
        echauffementSelected: 0,
        coinMaternellesSelected: 0,
        sequenceSelected: 0,
        categorieSelected: 0,
        allSeance1:[],
        allSeance2: [],
        listeEchauffement: {},
        listeEchauffementActivite: [],
        listeCoinMaternelles: {},
        listeCoinMaternellesActivite: [],
        listeCompetence: {},
        listeSequence: {},
        listeCategorie: {},
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      }, () => {
        this.loadCompetence()
      })
    }
  }

  changeCompetence(id) {
    if(id !== this.state.competenceSelected) {
      this.setState({
        competenceSelected: id,
        sequenceSelected: 0,
        echauffementSelected: 0,
        coinMaternellesSelected: 0,
        categorieSelected: 0,
        allSeance1:[],
        allSeance2: [],
        listeEchauffement: {},
        listeEchauffementActivite: [],
        listeCoinMaternelles: {},
        listeCoinMaternellesActivite: [],
        listeSequence: {},
        listeCategorie: {},
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      }, () => {
        this.loadSequence()
      })
    }
  }

  changeSequence(id,name) {
    if(id !== this.state.sequenceSelected) {
      this.setState({
        sequenceSelected: id,
        allSeance1:[],
        allSeance2: [],
        listeEchauffement: {},
        listeEchauffementActivite: [],
        listeCoinMaternelles: {},
        listeCoinMaternellesActivite: [],
        listeCategorie: {},
        activiteSelected: name,
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      },() => {
        this.loadCategorie()
      })
    }
  }

  changeCategorie(id) {
    if(id !== this.state.categorieSelected) {
      this.setState({
        categorieSelected: id,
        allSeance1:[],
        allSeance2: [],
        selectedSeance: null,
        showSeance: false,
        showEchauffement2: false,
        showCoinMaternelles2: false,
        showCroquis: false,
        showMateriel: false
      },() => {
        this.loadSeance()
      })
    }
  }

  nbMaterielInc(nbMateriel, inc, current) {
    nbMateriel += inc

    if(nbMateriel >= 1) {
      if(inc < 0) {
        var csvMateriel = this.state.csvMateriel

        if(current !== undefined) {
          delete csvMateriel[current]
        } else {
          delete csvMateriel[nbMateriel]
        }

        this.setState({
          csvMateriel: csvMateriel,
          nbMateriel: nbMateriel
        })
      } else {
        var csvMateriel = this.state.csvMateriel

        var key = parseInt(Object.keys(csvMateriel)[Object.keys(csvMateriel).length-1], 10) + 1

        if(key >= 0) {
          csvMateriel[key] = {'materiel': '', 'legende': ''}
        } else {
          csvMateriel[nbMateriel] = {'materiel': '', 'legende': ''}
        }

        this.setState({
          csvMateriel: csvMateriel,
          nbMateriel: nbMateriel
        })
      }
    } else {
      this.setState({
        csvMateriel: {},
        nbMateriel: 0
      })
    }
  }

  nbCroquisInc(nbCroquis, inc, current) {
    nbCroquis += inc

    if(nbCroquis >= 1) {
      if(inc < 0) {
        var croquisSected = this.state.croquisSected

        if(current !== undefined) {
          delete croquisSected[current]
        } else {
          delete croquisSected[nbCroquis]
        }
        this.setState({
          croquisSected: croquisSected,
          nbCroquis: nbCroquis
        })
      } else {
        var croquisSected = this.state.croquisSected

        var key = 0
        if(Object.keys(croquisSected).length > 0) {
          key = parseInt(Object.keys(croquisSected)[Object.keys(croquisSected).length-1], 10) + 1
        }

        if(key >= 0) {
          croquisSected[key] = {'croquis': '', 'legende': ''}
        } else {
          croquisSected[nbCroquis] = {'croquis': '', 'legende': ''}
        }

        this.setState({
          croquisSected: croquisSected,
          nbCroquis: nbCroquis
        })
      }
    } else {
      this.setState({
        croquisSected: {},
        nbCroquis: 0
      })
    }
  }

  nbVariableInc(nbVariable, inc, current) {
    nbVariable += inc

    if(nbVariable >= 1) {
      if(inc < 0) {
        var csvVariable = this.state.csvVariable

        if(current !== undefined) {
          delete csvVariable[current]
        } else {
          delete csvVariable[nbVariable]
        }
        this.setState({
          csvVariable: csvVariable,
          nbVariable: nbVariable
        })
      } else {
        var csvVariable = this.state.csvVariable

        var key = 0
        if(Object.keys(csvVariable).length > 0) {
          key = parseInt(Object.keys(csvVariable)[Object.keys(csvVariable).length-1], 10) + 1
        }

        csvVariable[key] = ''

        this.setState({
          csvVariable: csvVariable,
          nbVariable: nbVariable
        })
      }
    } else {
      this.setState({
        csvVariable: {},
        nbVariable: 0
      })
    }
  }

  updateInputSeance(id,value,index) {
    let allSeance2 = this.state.allSeance2
    allSeance2[index][id] = value
    this.setState({
      allSeance2: allSeance2
    })
  }

  listMateriel(id, value, key) {
    var csvMateriel = this.state.csvMateriel
    if (csvMateriel[id] === undefined) {
      csvMateriel[id] = {}
    }
    csvMateriel[id][key] = value
    this.setState({
      csvMateriel: csvMateriel
    })
  }

  changeCroquis(id, value, key) {
    var croquisSected = this.state.croquisSected
    console.log(croquisSected)
    if (croquisSected[id] === undefined) {
      croquisSected[id] = {}
    }

    if(value === "") {
      croquisSected = {}
    } else {
      croquisSected[id][key] = value
    }
    console.log(id)
    console.log(value)
    console.log(key)
    console.log(croquisSected)
    this.setState({
      croquisSected: croquisSected
    })
  }

  listVariable(id, value) {
    var csvVariable = this.state.csvVariable
    csvVariable[id] = value
    this.setState({
      csvVariable: csvVariable
    })
  }

  componentDidMount() {
    this.loadCycle();
  } 

  loadCycle() {
    axios.get('/api/getCycle') 
    .then((res) => {
	console.log(res.data)
      if(res.data) {
        this.setState({
          listeCycle: res.data.listeCycle,
          buttonCoinMaternellesSelected: false,
          buttonEchauffementSelected: false,
        })
      }
    });
  }

  loadCompetence() {
    const formData = new FormData()

    axios.get('/api/getCompetence', { params: { id_cycle: this.state.cycleSelected } }) 
    .then((res) => {
	console.log(res.data)
      if(res.data) {
        this.setState({
          listeCompetence: res.data.listeCompetence ? res.data.listeCompetence : {}
        })
      }
    });
  }

  loadEchauffement() {
    const formData = new FormData()

    axios.get('/api/getEchauffement') 
    .then((res) => {
      if(res.data) {
        this.changeCycle(null)
        this.setState({
          listeEchauffement: res.data.listeEchauffement,
          listeCoinMaternelles: {},
          listeCoinMaternellesActivite: [],
          coinMaternellesSelected: 0,
          buttonCoinMaternellesSelected: false,
          buttonEchauffementSelected: true,
        })
      }
    });
  }

  loadEchauffementActivite() {
    const formData = new FormData()

    axios.get('/api/getEchauffementActivite', { params: { id_echauffement: this.state.echauffementSelected } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          listeEchauffementActivite: res.data.listeEchauffementActivite
        })
      }
    });
  }

  loadCoinMaternelles() {
    const formData = new FormData()

    axios.get('/api/getCoinMaternelles') 
    .then((res) => {
      if(res.data) {
        this.changeCycle(null)
        this.setState({
          listeCoinMaternelles: res.data.listeCoinMaternelles,
          listeEchauffement: {},
          listeEchauffementActivite: [],
          echauffementSelected: 0,
          buttonCoinMaternellesSelected: true,
          buttonEchauffementSelected: false,
        })
      }
    });
  }

  loadCoinMaternellesActivite() {
    const formData = new FormData()

    axios.get('/api/getCoinMaternellesActivite', { params: { id_coin_maternelles: this.state.coinMaternellesSelected } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          listeCoinMaternellesActivite: res.data.listeCoinMaternellesActivite
        })
      }
    });
  }

  loadSequence() {
    const formData = new FormData()

    axios.get('/api/getSequence', { params: { id_competence: this.state.competenceSelected } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          listeSequence: res.data.listeSequence,
        })
      }
    });
  }

  loadCategorie() {
    const formData = new FormData()

    axios.get('/api/getCategorie', { params: { id_sequence: this.state.sequenceSelected } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          listeCategorie: res.data.listeCategorie,
          categorieSelected: res.data.listeCategorie.length > 0 ? 0 : -1
        }, () => {
          if(this.state.categorieSelected === -1) {
            this.loadSeance()
          }
        })
      }
    });
  }

  loadSeance() {
    const formData = new FormData()

    axios.get('/api/getSeance', { params: { id_categorie: this.state.categorieSelected, id_sequence: this.state.sequenceSelected } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          allSeance1: res.data.allSeance,
          allSeance2: res.data.allSeance1,
          showSeance: false,
          showCroquis: false,
          showMateriel: false
        })
      }
    });
  }

  async loadDetailSeance(id) {
    const formData = new FormData()

    await axios.get('/api/getDetailSeance', { params: { id: id } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          detailSeance: res.data.detailSeance
        })
      }
    });
  }

  async loadDetailSeanceEchauffement(id) {
    const formData = new FormData()

    await axios.get('/api/getDetailSeanceEchauffement', { params: { id: id } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          detailSeanceEchauffement: res.data.detailSeance
        })
      }
    });
  }

  async loadDetailSeanceCoinMaternelles(id) {
    const formData = new FormData()

    await axios.get('/api/getDetailSeanceCoinMaternelles', { params: { id: id } }) 
    .then((res) => {
      if(res.data) {
        this.setState({
          detailSeanceCoinMaternelles: res.data.detailSeance
        })
      }
    });
  }

  checkonChange(value,position) {
    position === 1 ? this.setState({checked1:!value, checked2: false}) : this.setState({checked1: false, checked2: !value}) 
  }

  createSeance(titreActivite,objectif,descriptif) {

    const formData = new FormData()

    formData.append('titreActivite', titreActivite)
    formData.append('objectif', objectif)
    formData.append('descriptif', descriptif)
    formData.append('materiel', JSON.stringify(this.state.csvMateriel))
    formData.append('variable', JSON.stringify(this.state.csvVariable))
    formData.append('croquis', JSON.stringify(this.state.croquisSected))
    formData.append('id_categorie', this.state.categorieSelected)
    formData.append('id_sequence', this.state.sequenceSelected)

    axios.post('/api/createSeance', formData) 
    .then((res) => {
      this.boolShow(true)
      this.loadSeance()
    });
  }

  createEchauffement(titreActivite,descriptif) {

    const formData = new FormData()

    formData.append('id_echauffement', this.state.echauffementSelected)
    formData.append('titreActivite', titreActivite)
    formData.append('descriptif', descriptif)
    formData.append('variable', JSON.stringify(this.state.csvVariable))

    axios.post('/api/createEchauffement', formData) 
    .then((res) => {
      this.boolShowEchauffement(true)
      this.loadEchauffementActivite()
    });
  }

  createCoinMaternelles(titreActivite,descriptif) {

    const formData = new FormData()

    formData.append('id_coin_maternelles', this.state.coinMaternellesSelected)
    formData.append('titreActivite', titreActivite)
    formData.append('descriptif', descriptif)
    formData.append('variable', JSON.stringify(this.state.csvVariable))

    axios.post('/api/createCoinMaternelles', formData) 
    .then((res) => {
      this.boolShowCoinMaternelles(true)
      this.loadCoinMaternellesActivite()
    });
  }

  deleteSeance(id) {
    const formData = new FormData()

    formData.append('id', id)
    axios.post('/api/deleteSeance', formData) 
    .then((res) => {
      this.loadSeance()
    });
  }

  deleteEchauffement(id) {
    const formData = new FormData()

    formData.append('id', id)
    axios.post('/api/deleteEchauffement', formData) 
    .then((res) => {
      this.loadEchauffementActivite()
    });
  }

  deleteCoinMaternelles(id) {
    const formData = new FormData()

    formData.append('id', id)
    axios.post('/api/deleteCoinMaternelles', formData) 
    .then((res) => {
      this.loadCoinMaternellesActivite()
    });
  }

  modifSeance(modifId,titre_activite,objectif,content) {
    const formData = new FormData()

    formData.append('id', modifId)
    formData.append('titre_activite', titre_activite)
    formData.append('objectif', objectif)
    formData.append('descriptif', content)
    formData.append('materiel', JSON.stringify(this.state.csvMateriel))
    formData.append('variable', JSON.stringify(this.state.csvVariable))
    formData.append('croquis', JSON.stringify(this.state.croquisSected))

    axios.post('/api/modifSeance', formData)
    .then((res) => {
      this.boolShow3(true)
      this.loadSeance()
    })
    .catch(err => {
      console.log(err)
    });
  }

  modifSeance2(modifId,titre_activite,content) {
    const formData = new FormData()

    formData.append('id', modifId)
    formData.append('titre_activite', titre_activite)
    formData.append('descriptif', content)
    formData.append('variable', JSON.stringify(this.state.csvVariable))

    axios.post('/api/modifSeance2', formData)
    .then((res) => {
      this.boolShow2Echauffement(true)
      this.loadEchauffementActivite()
    })
    .catch(err => {
      console.log(err)
    });
  }

  modifSeance3(modifId,titre_activite,content) {
    const formData = new FormData()

    formData.append('id', modifId)
    formData.append('titre_activite', titre_activite)
    formData.append('descriptif', content)
    formData.append('variable', JSON.stringify(this.state.csvVariable))

    axios.post('/api/modifSeance3', formData)
    .then((res) => {
      this.boolShow2CoinMaternelles(true)
      this.loadCoinMaternellesActivite()
    })
    .catch(err => {
      console.log(err)
    });
  }

  deconnexion() {
    cookies.remove('authenticated');
    history.go('/');
  }

  render() {

    let visible = this.state.allSeance1.length > 0 ? true : false
    let visible2 = this.state.listeEchauffementActivite.length > 0 ? true : false
    let visible3 = this.state.listeCoinMaternellesActivite.length > 0 ? true : false

    return (
      <div>
        <div className="App">
          <header className="header">
            <h3 className="title-header">Accès Eps</h3>
            <Deconnexion deconnexion={this.deconnexion.bind(this)}/>
          </header>
          <SelectCycle listeCycle={this.state.listeCycle} cycleSelected={this.state.cycleSelected} buttonEchauffementSelected={this.state.buttonEchauffementSelected} buttonCoinMaternellesSelected={this.state.buttonCoinMaternellesSelected} changeCycle={this.changeCycle.bind(this)} loadEchauffement={this.loadEchauffement.bind(this)} loadCoinMaternelles={this.loadCoinMaternelles.bind(this)} />
          <SelectCompetence listeCompetence={this.state.listeCompetence} competenceSelected={this.state.competenceSelected} changeCompetence={this.changeCompetence.bind(this)} />
          <SelectEchauffement listeEchauffement={this.state.listeEchauffement} echauffementSelected={this.state.echauffementSelected} changeEchauffement={this.changeEchauffement.bind(this)}/>
          <SelectCoinMaternelles listeCoinMaternelles={this.state.listeCoinMaternelles} coinMaternellesSelected={this.state.coinMaternellesSelected} changeCoinMaternelles={this.changeCoinMaternelles.bind(this)}/>
          <SelectSequence listeSequence={this.state.listeSequence} sequenceSelected={this.state.sequenceSelected} changeSequence={this.changeSequence.bind(this)} />
          <SelectCategorie listeCategorie={this.state.listeCategorie} categorieSelected={this.state.categorieSelected} changeCategorie={this.changeCategorie.bind(this)} showModal={this.state.showModal} boolShow={this.boolShow.bind(this)} routeFile={this.state.activiteSelected.toString()}/>
          <Dashboard showModal={this.state.showModal} boolShow={this.boolShow.bind(this)} categorieSelected={this.state.categorieSelected} />
          <Dashboard2 showModalEchauffement={this.state.showModalEchauffement} boolShowEchauffement={this.boolShowEchauffement.bind(this)} echauffementSelected={this.state.echauffementSelected} />
          <Dashboard3 showModalCoinMaternelles={this.state.showModalCoinMaternelles} boolShowCoinMaternelles={this.boolShowCoinMaternelles.bind(this)} coinMaternellesSelected={this.state.coinMaternellesSelected} />
          
          <NewSeanceModal key={this.state.key} initialValue={this.state.initialValue} csvMateriel={this.state.csvMateriel} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} showModal={this.state.showModal} boolShow={this.boolShow.bind(this)} nbMateriel={this.state.nbMateriel} nbVariable={this.state.nbVariable} nbCroquis={this.state.nbCroquis} listMateriel={this.listMateriel.bind(this)} listVariable={this.listVariable.bind(this)} nbMaterielInc={this.nbMaterielInc.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} nbCroquisInc={this.nbCroquisInc.bind(this)} createSeance={this.createSeance.bind(this)} checkonChange={this.checkonChange.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} changeCroquis={this.changeCroquis.bind(this)} croquisSected={this.state.croquisSected} />
          <NewSeanceModal2 key={this.state.key3} initialValue={this.state.initialValue} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} showModalEchauffement={this.state.showModalEchauffement} boolShowEchauffement={this.boolShowEchauffement.bind(this)} nbVariable={this.state.nbVariable} listVariable={this.listVariable.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} createEchauffement={this.createEchauffement.bind(this)} checkonChange={this.checkonChange.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} />
          <NewSeanceModal3 key={this.state.key3} initialValue={this.state.initialValue} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} showModalCoinMaternelles={this.state.showModalCoinMaternelles} boolShowCoinMaternelles={this.boolShowCoinMaternelles.bind(this)} nbVariable={this.state.nbVariable} listVariable={this.listVariable.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} createCoinMaternelles={this.createCoinMaternelles.bind(this)} checkonChange={this.checkonChange.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} />
          
          <ModifSeance key={this.state.key2} initialValue={this.state.initialValue} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} modifId={this.state.modifId} titre_activite={this.state.titre_activite} objectif={this.state.objectif} csvMateriel={this.state.csvMateriel} csvVariable={this.state.csvVariable} showModal3={this.state.showModal3} boolShow3={this.boolShow3.bind(this)} nbMateriel={this.state.nbMateriel} nbVariable={this.state.nbVariable} nbCroquis={this.state.nbCroquis} listMateriel={this.listMateriel.bind(this)} listVariable={this.listVariable.bind(this)} nbMaterielInc={this.nbMaterielInc.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} nbCroquisInc={this.nbCroquisInc.bind(this)} modifSeance={this.modifSeance.bind(this)} checkonChange={this.checkonChange.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} croquisSected={this.state.croquisSected} changeCroquis={this.changeCroquis.bind(this) } />
          <ModifSeance2 key={this.state.key4} initialValue={this.state.initialValue} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} modifId={this.state.modifId} titre_activite={this.state.titre_activite} showModalEchauffement2={this.state.showModalEchauffement2} boolShow2Echauffement={this.boolShow2Echauffement.bind(this)} csvVariable={this.state.csvVariable} nbVariable={this.state.nbVariable} listVariable={this.listVariable.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} createEchauffement={this.createEchauffement.bind(this)} checkonChange={this.checkonChange.bind(this)} modifSeance2={this.modifSeance2.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} />
          <ModifSeance3 key={this.state.key4} initialValue={this.state.initialValue} content={this.state.content} handleEditorChange={this.handleEditorChange.bind(this)} modifId={this.state.modifId} titre_activite={this.state.titre_activite} showModalCoinMaternelles2={this.state.showModalCoinMaternelles2} boolShow2CoinMaternelles={this.boolShow2CoinMaternelles.bind(this)} csvVariable={this.state.csvVariable} nbVariable={this.state.nbVariable} listVariable={this.listVariable.bind(this)} nbVariableInc={this.nbVariableInc.bind(this)} createCoinMaternelles={this.createCoinMaternelles.bind(this)} checkonChange={this.checkonChange.bind(this)} modifSeance3={this.modifSeance3.bind(this)} onDrop={this.onDrop} onDrop2={this.onDrop2} />
          
          <div className="col-md-12 noPadding2 bodyFlex">
            <div className="col-md-4">
              <div className="row contentAtelier">
                <Fade left when={visible}>
                  <div className="col-md-12 noPadding2" style={{ 'overflow': 'scroll', 'maxHeight': '700px' }}>
                    <AllSeance key={Math.random} allSeance1={this.state.allSeance1} displaySeance={this.displaySeance.bind(this)} showSeance={this.state.showSeance} deleteSeance={this.deleteSeance.bind(this)} boolShow3={this.boolShow3.bind(this)} showModal3={this.state.showModal3} loadDetailSeance={this.loadDetailSeance.bind(this)} copyCodeToClipboard={this.copyCodeToClipboard.bind(this)} />
                  </div>
                </Fade>

                <Fade left when={visible2}>
                  <div className="col-md-12 noPadding2" style={{ 'overflow': 'scroll', 'maxHeight': '700px' }}>
                    <AllSeance2 key={Math.random} listeEchauffementActivite={this.state.listeEchauffementActivite} displayEchauffement={this.displayEchauffement.bind(this)} showEchauffement2={this.state.showEchauffement2} deleteEchauffement={this.deleteEchauffement.bind(this)} boolShow2Echauffement={this.boolShow2Echauffement.bind(this)} showModal3={this.state.showModal3} loadDetailSeanceEchauffement={this.loadDetailSeanceEchauffement.bind(this)} copyCodeToClipboard={this.copyCodeToClipboard.bind(this)} />
                  </div>
                </Fade>

                <Fade left when={visible3}>
                  <div className="col-md-12 noPadding2" style={{ 'overflow': 'scroll', 'maxHeight': '700px' }}>
                    <AllSeance3 key={Math.random} listeCoinMaternellesActivite={this.state.listeCoinMaternellesActivite} displayCoinMaternelles={this.displayCoinMaternelles.bind(this)} showCoinMaternelles2={this.state.showCoinMaternelles2} deleteCoinMaternelles={this.deleteCoinMaternelles.bind(this)} boolShow2CoinMaternelles={this.boolShow2CoinMaternelles.bind(this)} showModal3={this.state.showModal3} loadDetailSeanceCoinMaternelles={this.loadDetailSeanceCoinMaternelles.bind(this)} copyCodeToClipboard={this.copyCodeToClipboard.bind(this)} />
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row contentAtelier">
                <DisplaySeance detailSeance={this.state.detailSeance} showSeance={this.state.showSeance} showCroquis={this.state.showCroquis} showMateriel={this.state.showMateriel} sequenceSelected={this.state.sequenceSelected} listeSequence={this.state.listeSequence} activiteSelected={this.state.activiteSelected} displayCroquis={this.displayCroquis.bind(this)} displayMateriel={this.displayMateriel.bind(this)} displaySeance2={this.displaySeance2.bind(this)} numBubble={this.state.numBubble}/>
                <DisplayEchauffement detailSeanceEchauffement={this.state.detailSeanceEchauffement} showEchauffement2={this.state.showEchauffement2} selectedEchauffement={this.state.selectedEchauffement} numBubble={this.state.numBubble}/>
                <DisplayCoinMaternelles detailSeanceCoinMaternelles={this.state.detailSeanceCoinMaternelles} showCoinMaternelles2={this.state.showCoinMaternelles2} selectedCoinMaternelles={this.state.selectedCoinMaternelles} numBubble={this.state.numBubble}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardClass;
